import React, { createContext, useContext, useEffect, useReducer } from 'react';
import LoadingContext from './Loading';

const IframeSrcDispatchContext = createContext();
const IframeSrcStateContext = createContext();

const { useLoadingDispatch } = LoadingContext;

const initialState = {};
const reducer = (state, action) => {
  let returnValue;
  switch (action.type) {
    case 'set values': {
      returnValue = {
        ...state,
        ...(action.iframeSrc ? { iframeSrc: action.iframeSrc } : undefined),
      };
      break;
    }
    default: {
      throw new Error(`Unsupported action: ${action.type}`);
    }
  }
  return returnValue;
}

function IframeSrcProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const loadingDispatch = useLoadingDispatch();

  // Load initial state from the page URL or localStorage
  useEffect(() => {
    // Get parameters passed as part of the URL
    const params = Object.fromEntries(new URLSearchParams(window.location.search).entries());
    const partnerId = params.partnerId || localStorage.getItem('partnerId');
    // If a partnerId is not specified on initial page load, stop the iframe loading spinner
    if (!partnerId) {
      loadingDispatch({
        type: 'set is iframe loading',
        isIframeLoading: false,
      });
    }
  }, [loadingDispatch]);

  return (
    <IframeSrcDispatchContext.Provider value={dispatch}>
      <IframeSrcStateContext.Provider value={state}>
        {children}
      </IframeSrcStateContext.Provider>
    </IframeSrcDispatchContext.Provider>
  );
}

const useIframeSrcState = () => {
  const context = useContext(IframeSrcStateContext);
  if (context === undefined) {
    throw new Error('useIframeSrcState must be used within a IframeSrcProvider');
  }
  return context;
}

const useIframeSrcDispatch = () => {
  const context = useContext(IframeSrcDispatchContext);
  if (context === undefined) {
    throw new Error('useIframeSrcDispatch must be used within a IframeSrcProvider');
  }
  return context;
}

const IframeSrcContext = {
  IframeSrcProvider,
  useIframeSrcDispatch,
  useIframeSrcState,
};
export default IframeSrcContext;
