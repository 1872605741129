// Source: https://docs.developer.amazonservices.com/en_US/dev_guide/DG_Endpoints.html

module.exports = {
  regions: {
    NA: {
      name: 'North America',
      marketplaceIds: [
        'ATVPDKIKX0DER',
        'A2EUQ1WTGCTBG2',
        'A1AM78C64UM0Y8',
        'A2Q3Y263D00KWC',
      ],
    },
    EU: {
      name: 'Europe',
      marketplaceIds: [
        'A1F83G8C2ARO7P',
        'A1PA6795UKMFR9',
        'A2VIGQ35RCS4UG',
        'ARBP9OOSHTCHU',
        'A1RKKUPIHCS9HS',
        'A13V1IB3VIYZZH',
        'A21TJRUUN4KGV',
        'APJ6JRA9NG5V4',
        'A1805IZSGTT6HS',
        'A1C3SOZRARQ6R3',
        'A17E79C6D8DWNP',
        'A2NODRKZP88ZB9',
        'A33AVAJ2PDY3EV',
        'AMEN7PMS3EDWL',
      ],
    },
    FE: {
      name: 'Far East',
      marketplaceIds: ['A39IBJ37TRP1C6', 'A1VC38T7YXB528', 'A19VAU5U5O7RUS'],
    },
  },
  countryCodes: {
    // North America
    BR: 'Brazil',
    CA: 'Canada',
    MX: 'Mexico',
    US: 'United States',

    // Europe
    AE: 'United Arab Emirates (U.A.E.)',
    BE: 'Belgium',
    DE: 'Germany',
    EG: 'Egypt',
    ES: 'Spain',
    FR: 'France',
    GB: 'UK',
    IN: 'India',
    IT: 'Italy',
    NL: 'Netherlands',
    PL: 'Poland',
    SA: 'Saudi Arabia',
    SE: 'Sweden',
    TR: 'Turkey',

    // Far East
    SG: 'Singapore',
    AU: 'Australia',
    JP: 'Japan',
  },
  marketplaceIds: {
    // North America
    A2Q3Y263D00KWC: 'BR',
    A2EUQ1WTGCTBG2: 'CA',
    A1AM78C64UM0Y8: 'MX',
    ATVPDKIKX0DER: 'US',

    // Europe
    A2VIGQ35RCS4UG: 'AE',
    AMEN7PMS3EDWL: 'BE',
    A1PA6795UKMFR9: 'DE',
    ARBP9OOSHTCHU: 'EG',
    A1RKKUPIHCS9HS: 'ES',
    A13V1IB3VIYZZH: 'FR',
    A1F83G8C2ARO7P: 'GB',
    A21TJRUUN4KGV: 'IN',
    APJ6JRA9NG5V4: 'IT',
    A1805IZSGTT6HS: 'NL',
    A1C3SOZRARQ6R3: 'PL',
    A17E79C6D8DWNP: 'SA',
    A2NODRKZP88ZB9: 'SE',
    A33AVAJ2PDY3EV: 'TR',

    // Far East
    A19VAU5U5O7RUS: 'SG',
    A39IBJ37TRP1C6: 'AU',
    A1VC38T7YXB528: 'JP',

    // "AY4ITWDNZAA14": "Japan (pending registration)",
    // Returns website with error message:
    //   "You have not completed registering {partner name} in Amazon.co.jp."
    //
    // "A3O7FHSIRK2OV3": "United Arab Emirates (pending registration)"
    // Returns website with error message:
    //   "You have not completed registering {id} in Amazon.ae."
  },
  stackIds: {
    // North America
    A2Q3Y263D00KWC: 'NA',
    A2EUQ1WTGCTBG2: 'NA',
    A1AM78C64UM0Y8: 'NA',
    ATVPDKIKX0DER: 'NA',

    // Europe
    A2VIGQ35RCS4UG: 'EU',
    A1PA6795UKMFR9: 'EU',
    ARBP9OOSHTCHU: 'EU',
    A1RKKUPIHCS9HS: 'EU',
    A13V1IB3VIYZZH: 'EU',
    A1F83G8C2ARO7P: 'EU',
    A21TJRUUN4KGV: 'EU',
    APJ6JRA9NG5V4: 'EU',
    A1805IZSGTT6HS: 'EU',
    A1C3SOZRARQ6R3: 'EU',
    A17E79C6D8DWNP: 'EU',
    A2NODRKZP88ZB9: 'EU',
    A33AVAJ2PDY3EV: 'EU',
    AMEN7PMS3EDWL: 'EU',

    // Far East
    A19VAU5U5O7RUS: 'FE',
    A39IBJ37TRP1C6: 'FE',
    A1VC38T7YXB528: 'FE',
  },
  sellerCentralUrls: {
    // North America
    A2Q3Y263D00KWC: 'sellercentral.amazon.com.br', // Brazil
    A2EUQ1WTGCTBG2: 'sellercentral.amazon.ca', // Canada
    A1AM78C64UM0Y8: 'sellercentral.amazon.com.mx', // Mexico
    ATVPDKIKX0DER: 'sellercentral.amazon.com', // US

    // Europe
    A2VIGQ35RCS4UG: 'sellercentral.amazon.ae', // AE
    AMEN7PMS3EDWL: 'sellercentral.amazon.com.be', // Belgium
    A1PA6795UKMFR9: 'sellercentral.amazon.de', // Germany
    // "ARBP9OOSHTCHU": // Egypt
    A1RKKUPIHCS9HS: 'sellercentral.amazon.es', // Spain
    A13V1IB3VIYZZH: 'sellercentral.amazon.fr', // France
    A1F83G8C2ARO7P: 'sellercentral.amazon.co.uk', // UK
    A21TJRUUN4KGV: 'sellercentral.amazon.in', // India
    APJ6JRA9NG5V4: 'sellercentral.amazon.it', // Italy
    A1805IZSGTT6HS: 'sellercentral.amazon.nl', // Netherlands
    A1C3SOZRARQ6R3: 'sellercentral.amazon.pl', // Poland
    A17E79C6D8DWNP: 'sellercentral.amazon.sa', // Saudi Arabia
    A2NODRKZP88ZB9: 'sellercentral.amazon.se', // Sweden
    A33AVAJ2PDY3EV: 'sellercentral.amazon.com.tr', // Turkey

    // Far East
    A19VAU5U5O7RUS: 'sellercentral.amazon.sg', // Singapore
    A39IBJ37TRP1C6: 'sellercentral.amazon.com.au', // Australia
    A1VC38T7YXB528: 'sellercentral.amazon.co.jp', // Japan
  },
};
