import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { globalConfig } from '../configuration/config';

const Context = createContext();

const pendingRequests = [];
let cache = {};

function ApiProvider({ children }) {
  const [jwtToken, setJwtToken] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Get JWT token when Okta Auth is available
  const { authState, oktaAuth } = useOktaAuth();
  useEffect(() => {
    // TODO: See https://developer.okta.com/docs/guides/sign-into-spa/react/main/#refresh-tokens-and-spas for token refresh
    if (authState && authState.isAuthenticated) {
      setIsLoggedIn(true);
      setJwtToken(oktaAuth.getAccessToken());
    } else {
      setIsLoggedIn(false);
      setJwtToken(/* undefined */);
    }
  }, [authState, oktaAuth]);

  const clearCache = () => (cache = {});

  // API request function that gets a single path and returns the JSON
  // TODO: Temporarily caching results during development, this may need tweaking longer-term
  const request = useCallback(
    async (path, options = {}, ignoreCache = false) => {
      const method = options.method || 'GET';
      const headers = options.headers || {};
      if (!ignoreCache && method === 'GET' && path in cache) {
        return cache[path];
      }
      const url = new URL(path, globalConfig.get().apiUrl);
      return fetch(url, {
        ...options,
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          ...headers,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (method === 'GET') {
            cache[path] = data;
          } else {
            clearCache();
          }
          return data;
        })
        .catch(console.error);
    },
    [jwtToken],
  );

  // Promisify the requests if jwtToken is not available yet
  const value = {
    isLoggedIn,
    request: isLoggedIn
      ? request
      : async (path, options = {}) =>
          new Promise((resolve) => {
            pendingRequests.push({
              resolve,
              args: [path, options],
            });
          }),
    clearCache,
  };

  // Resolve any pending requests when jwtToken becomes available
  useEffect(() => {
    if (jwtToken) {
      pendingRequests.forEach(async ({ resolve, args }) =>
        resolve(request(...args)),
      );
    }
  }, [jwtToken, request]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

const useApi = () => {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error('useApi must be used within a ApiProvider');
  }
  return context;
};

const ApiContext = {
  ApiProvider,
  useApi,
};

export default ApiContext;
