import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import {
  ApiContext,
  IframeSrcContext,
  LoadingContext,
  PartnersContext,
  RolesContext,
  SiteContext,
} from './providers';
import 'index.scss';
import OktaAuthentication from './OktaAuthentication';
import App from 'App';
import reportWebVitals from './reportWebVitals';
import {
  defaultConfig,
  globalConfig,
  globalConfigUrl,
} from './configuration/config';

require('./FontAwesome');

// Lazy to allow for config to be populated before init
const app = () => (
  <React.StrictMode>
    <BrowserRouter>
      <OktaAuthentication>
        <ApiContext.ApiProvider>
          <LoadingContext.LoadingProvider>
            <IframeSrcContext.IframeSrcProvider>
              <RolesContext.RolesProvider>
                <PartnersContext.PartnersProvider>
                  <SiteContext.SiteProvider>
                    <App />
                  </SiteContext.SiteProvider>
                </PartnersContext.PartnersProvider>
              </RolesContext.RolesProvider>
            </IframeSrcContext.IframeSrcProvider>
          </LoadingContext.LoadingProvider>
        </ApiContext.ApiProvider>
      </OktaAuthentication>
    </BrowserRouter>
  </React.StrictMode>
);

// https://profinit.eu/en/blog/build-once-deploy-many-in-react-dynamic-configuration-properties/
// before rendering, first fetch the global config:
console.debug('index.js, fetching global config from', globalConfigUrl);

fetch(globalConfigUrl, {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
})
  .then((response) => response.json())
  .then((config) => {
    globalConfig.set(config);
    console.debug('Global config fetched: ', config);

    return app();
  })
  .catch((e) => {
    // In development, treat this case as a warning, render the app and use default config values.
    // In production (and test) on the other hand, show error instead of rendering the app.
    console.warn(e);

    if (process.env.NODE_ENV === 'development') {
      console.warn(
        `Failed to load global configuration from '${globalConfigUrl}', using the default configuration instead:`,
        defaultConfig,
      );
      globalConfig.set(defaultConfig);

      return app();
    }
    const errorMessage =
      'Error while fetching global config, the App wil not be rendered. (This is NOT a React error.)';
    console.error(
      errorMessage,
      `Have you provided the config file '${globalConfigUrl}'?`,
      e,
    );

    return <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>;
  })
  .then((reactElement) => {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(reactElement);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
