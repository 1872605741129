import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { globalConfig } from './configuration/config';

function OktaAuthentication({ children }) {
  const tokenRefreshTime = 1000 * 60 * 20; // 20 minutes
  const navigate = useNavigate();
  const [oktaAuth, setOktaAuth] = useState();
  const [refreshAuth, setRefreshAuth] = useState(false);

  useEffect(() => {
    setOktaAuth(new OktaAuth({
      clientId: globalConfig.get().oktaClientId,
      issuer: globalConfig.get().oktaIssuer,
      redirectUri: `${window.location.origin}/login/callback`,
      scopes: ['openid', 'profile', 'email', 'groups'],
      pkce: true,
      disableHttpsCheck: true,
      transformAuthState: async (oktaAuth, authState) => {
        authState.isRoleAllowed = (roleType) => {
          const { groups = [] } = authState.accessToken.claims;
          return groups.includes(roleType);
        };
        return authState;
      },
    }));

    setTimeout(() => {
      setRefreshAuth(true);
    }, tokenRefreshTime);
  }, [tokenRefreshTime]);

  useEffect(() => {
    if (refreshAuth) {
      setRefreshAuth(false);
      oktaAuth.tokenManager.renew('accessToken').then((newToken) => {
        setTimeout(() => {
          setRefreshAuth(true);
        }, tokenRefreshTime);
      });     
    }
  }, [refreshAuth, oktaAuth, tokenRefreshTime])

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(
      toRelativeUrl(originalUri || '/', window.location.origin),
      { replace: true },
    );
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
}

export default OktaAuthentication;
