import React, { createContext, useContext, useReducer } from 'react';

const SiteDispatchContext = createContext();
const SiteStateContext = createContext();

const OPTIONS = [
  {
    id: 'sellercentral',
    name: 'Seller Central',
  },
  {
    id: 'advertising',
    name: 'Advertising/Campaign Manager',
  },
];

const isValidSiteIdentifier = (value) => OPTIONS.map(({ id }) => id).includes(value);

const setParam = (name, value) => {
  const params = new URL(window.location.href).searchParams;
  params.set(name, value);
  window.history.replaceState(null, null, `?${params}`);
};

const initialState = (() => {
  const params = new URL(window.location.href).searchParams;
  const siteParam = params.get('site');
  return {
    siteOptions: OPTIONS,
    site: isValidSiteIdentifier(siteParam) ? siteParam : 'sellercentral',
    pathname: params.get('pathname'),
  };
})();

const reducer = (state, action) => {
  let returnValue = { ...state };
  switch (action.type) {
    case 'set values': {
      if (action.site && isValidSiteIdentifier(action.site)) {
        returnValue.site = action.site;
        setParam('site', action.site);
      }
      if (action.pathname) {
        returnValue.pathname = action.pathname;
        setParam('pathname', action.pathname);
      }
      break;
    }
    default: {
      throw new Error(`Invalid action: ${action.type}`);
    }
  }
  return returnValue;
};

function SiteProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <SiteDispatchContext.Provider value={dispatch}>
      <SiteStateContext.Provider value={state}>
        {children}
      </SiteStateContext.Provider>
    </SiteDispatchContext.Provider>
  );
}

const useSiteState = () => {
  const context = useContext(SiteStateContext);
  if (context === undefined) {
    throw new Error('useSiteState must be used within a SiteProvider');
  }
  return context;
};

const useSiteDispatch = () => {
  const context = useContext(SiteDispatchContext);
  if (context === undefined) {
    throw new Error('useSiteDispatch must be used within a SiteProvider');
  }
  return context;
};

const SiteContext = {
  SiteProvider,
  useSiteDispatch,
  useSiteState,
};
export default SiteContext;
