import React, { createContext, useContext, useReducer } from 'react';

const LoadingDispatchContext = createContext();
const LoadingStateContext = createContext();

const initialState = {
  isRolesDataLoading: true,
  isPartnerDataLoading: true,
  isIframeLoading: true,
  isRefreshingSession: true,
  statusText: null,
};
const reducer = (state, action) => {
  let returnValue;
  switch (action.type) {
    case 'set is roles data loading': {
      returnValue = {
        ...state,
        isRolesDataLoading: action.isRolesDataLoading,
      };
      break;
    }
    case 'set is partner data loading': {
      returnValue = {
        ...state,
        isPartnerDataLoading: action.isPartnerDataLoading,
      };
      break;
    }
    case 'set is iframe loading': {
      returnValue = {
        ...state,
        isIframeLoading: action.isIframeLoading,
      };
      if (!action.isIframeLoading) {
        returnValue.isRefreshingSession = false;
      }
      break;
    }
    case 'set status text': {
      returnValue = {
        ...state,
        statusText: action.statusText,
      };
      if (action.statusText === 'logging into seller central...') {
        returnValue.isRefreshingSession = true;
      }
      break;
    }
    case 'set refreshing session': {
      returnValue = {
        ...state,
        isRefreshingSession: action.isRefreshingSession,
      };
      break;
    }
    default: {
      throw new Error(`Invalid action: ${action.type}`);
    }
  }
  return returnValue;
}

function LoadingProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <LoadingDispatchContext.Provider value={dispatch}>
      <LoadingStateContext.Provider value={state}>
        {children}
      </LoadingStateContext.Provider>
    </LoadingDispatchContext.Provider>
  );
}

const useLoadingState = () => {
  const context = useContext(LoadingStateContext);
  if (context === undefined) {
    throw new Error('useLoadingState must be used within a LoadingProvider');
  }
  return context;
}

const useLoadingDispatch = () => {
  const context = useContext(LoadingDispatchContext);
  if (context === undefined) {
    throw new Error('useLoadingDispatch must be used within a LoadingProvider');
  }
  return context;
}

const LoadingContext = {
  LoadingProvider,
  useLoadingDispatch,
  useLoadingState,
};
export default LoadingContext;
