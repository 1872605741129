import React, { createContext, useContext, useEffect, useState } from 'react';
import ApiContext from './Api';
import LoadingContext from './Loading';
import RolesContext from './Roles';

const { useApi } = ApiContext;
const { useLoadingDispatch } = LoadingContext;
const { useRolesState } = RolesContext;

const PartnersStateContext = createContext();

function PartnersProvider({ children }) {
  const { request } = useApi();
  const loadingDispatch = useLoadingDispatch();

  const { roleId } = useRolesState();

  // Store partner data from API
  const [partners, setPartners] = useState([]);

  // Store user selection
  const [partnerId, setPartnerId] = useState(() =>
    (new URLSearchParams(window.location.search)).get('partnerId')
  );
  const [marketplaceId, setMarketplaceId] = useState(() =>
    (new URLSearchParams(window.location.search)).get('marketplaceId')
  );

  // Get partners this user can access from the API based on selected roleId
  useEffect(() => {
    if (!roleId) {
      return;
    }
    (async () => {
      loadingDispatch({
        type: 'set is partner data loading',
        isPartnerDataLoading: true,
      });
      const data = await request(`/gk/partners?${new URLSearchParams({ roleId })}`);
      setPartners(data);
      loadingDispatch({
        type: 'set is partner data loading',
        isPartnerDataLoading: false,
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId])

  // Find the selected partner in the partners data, and get the available marketplaces
  const [marketplaces, setMarketplaces] = useState([]);
  useEffect(() => {
    if (partners?.[partnerId]) {
      const selectedPartner = Object.entries(partners).find(([x]) => x === partnerId);
      const marketplacesAvailable = selectedPartner ? selectedPartner[1].marketplaces : [];
      setMarketplaces(marketplacesAvailable);
      // If no marketplace is selected yet, or if the current marketplace is not available
      // in the new partner, select a new marketplace. Prioritize US, UK, or Australia.
      if (!marketplaceId || !marketplacesAvailable.includes(marketplaceId)) {
        const defaultMarketplaceId = ['ATVPDKIKX0DER', 'A1F83G8C2ARO7P', 'A39IBJ37TRP1C6']
          .find((x) => marketplacesAvailable.includes(x));
        setMarketplaceId(defaultMarketplaceId);
        // Store marketplaceId in URL parameters
        const params = new URLSearchParams(window.location.search);
        params.set('marketplaceId', defaultMarketplaceId);
        window.history.replaceState(null, null, `?${params}`);
      }
    }
  }, [partners, partnerId, marketplaceId]);

  return (
    <PartnersStateContext.Provider value={{
      partners,
      partnerId,
      setPartnerId: (value) => {
        if (value) {
          const params = new URLSearchParams(window.location.search);
          params.set('partnerId', value);
          window.history.replaceState(null, null, `?${params}`);
          setPartnerId(value);
        }
      },
      marketplaces,
      marketplaceId,
      setMarketplaceId: (value) => {
        let id = value;
        if (!marketplaces.includes(id)) {
          id = marketplaces[0]
        }
        if (id) {
          const params = new URLSearchParams(window.location.search);
          params.set('marketplaceId', id);
          window.history.replaceState(null, null, `?${params}`);
          setMarketplaceId(id);
        }
      },
    }}>
      {children}
    </PartnersStateContext.Provider>
  );
}

const usePartnersState = () => {
  const context = useContext(PartnersStateContext);
  if (context === undefined) {
    throw new Error('usePartnersState must be used within a PartnersProvider');
  }
  return context;
};

const PartnersContext = {
  PartnersProvider,
  usePartnersState,
};
export default PartnersContext;
