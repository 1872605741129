import Rive from '@rive-app/react-canvas';
import styles from './index.module.scss';

function RefreshingSession() {
  return (
    <>
      <div className={styles.Backdrop} />
      <div className={styles.Container}>
        <svg className="drop-shadow-md drop-shadow-2xl h-48 w-48" viewBox="0 0 90 90" xmlns="http://www.w3.org/2000/svg">
          <filter id="shadow" color-interpolation-filters="sRGB">
            <feDropShadow dx="2" dy="2" stdDeviation="3" flood-opacity="0.5"/>
          </filter>
          <filter id="light">
            <feSpecularLighting
              result="specOut"
              specularExponent="20"
              lightingColor="#333">
              <fePointLight x="50" y="75" z="200" />
            </feSpecularLighting>
            <feComposite
              in="SourceGraphic"
              in2="specOut"
              operator="arithmetic"
              k1="0"
              k2="1"
              k3="1"
              k4="0" />
          </filter>
          <mask id="mask">
            <path d="M22.0992 77L2.19922 42.5L22.0992 8H61.8992L81.7992 42.5L61.8992 77H22.0992Z" fill="#fff" />
          </mask>
          <g style={{ filter: "url(#shadow)" }}>
            <path d="M22.0992 77L2.19922 42.5L22.0992 8H61.8992L81.7992 42.5L61.8992 77H22.0992Z" fill="#272848" stroke="#ffffff33" stroke-width="3" style={{ filter: "url(#light)" }} mask="url(#mask)" />
          </g>
        </svg>
        <Rive src="/refresh.riv" className={styles.Animation} />
        <p>Logging in…</p>
      </div>
    </>
  );
}

export default RefreshingSession;
