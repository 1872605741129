import React, { useEffect, useState } from 'react';
import Rive from '@rive-app/react-canvas';
import { Navbar, RefreshingSession, SelectPartner } from 'components';
import { ApiContext, LoadingContext, IframeSrcContext, SiteContext } from 'providers';
import { globalConfig } from 'configuration/config';
import styles from './index.module.scss';

const { useApi } = ApiContext;
const { useLoadingDispatch, useLoadingState } = LoadingContext;
const { useIframeSrcState } = IframeSrcContext;
const { useSiteDispatch } = SiteContext;

function Home() {
  const { request } = useApi();
  const { iframeSrc } = useIframeSrcState();
  const loadingDispatch = useLoadingDispatch();
  const { isRefreshingSession } = useLoadingState();
  const siteDispatch = useSiteDispatch();

  // Receive postMessage messages from the IFRAME
  useEffect(() => {
    function messageListener(event) {
      // Ensure the origin hostname ends with the API hostname
      const apiHostname = new URL(globalConfig.get().apiUrl).hostname;
      const originHostname = new URL(event.origin).hostname;
      if (originHostname.indexOf(apiHostname) === originHostname.length - apiHostname.length) {
        try {
          // Ignore Webpack hot reload messages
          if (typeof event.data === 'string' && event.data.indexOf('webpackHot') === 0) {
            return;
          }
          // Parse JSON responses
          let data;
          if (typeof event.data === 'string' && ['[', '{'].includes(event.data[0])) {
            try {
              data = JSON.parse(event.data);
            } catch (e) {
              console.error(e);
            }
          } else if (typeof event.data === 'object') {
            data = event.data;
          } else {
            throw new Error('Unsupported event.data type');
          }
          // Ignore events from react devtools when running locally
          if (['react-devtools-bridge', 'react-devtools-content-script'].includes(data.source)) {
            return;
          }
          switch(data.type) {
            case 'page_loaded':
              if (typeof data.pageLoaded === 'boolean') {
                loadingDispatch({
                  type: 'set is iframe loading',
                  isIframeLoading: !data.pageLoaded,
                });
                loadingDispatch({
                  type: 'set status text',
                  statusText: null,
                });
              }
              if (data.pathname && data.pageLoaded) {
                // Only update value in the location query parameters, not the context
                // provider (to prevent triggering an IFRAME refresh)
                const params = new URL(window.location.href).searchParams;
                params.set('pathname', data.pathname);
                window.history.replaceState(null, null, `?${params}`);
              }
              break;
            case 'site_redirect': 
              if (data.redirectToSite) {
                let redirectPathname = data.pathname;
                if (!redirectPathname) {
                  // Default the sellercentral home page to '/home'
                  redirectPathname = data.redirectToSite === 'sellercentral' ? '/home' : '/';
                }
                siteDispatch({
                  type: 'set values',
                  site: data.redirectToSite,
                  pathname: redirectPathname,
                });
              }
              break;
            case 'refresh_session':
              loadingDispatch({
                type: 'set status text',
                statusText: 'logging into seller central...',
              });
              loadingDispatch({
                type: 'set refreshing session',
                isRefreshingSession: true,
              });
              break;
            default:
              console.error('unhandled message posted');
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
    window.addEventListener('message', messageListener);
    return (() => {
      window.removeEventListener('message', messageListener)
    });
  }, [loadingDispatch, siteDispatch]);

  // Refresh IFRAME authentication cookies every 20 minutes
  const [isRefreshingCookies, setIsRefreshingCookies] = useState(false);
  useEffect(() => {
    if (iframeSrc && isRefreshingCookies) {
      const startSessionParams = new URLSearchParams({
        cookieHeartbeat: true,
        origin: window.location.origin,
      });
      request(
        `${new URL(iframeSrc).origin}/gk/start-session?${startSessionParams}`,
        {},
        true,
      );
      setIsRefreshingCookies(false);
    }
    const timer = setTimeout(() => setIsRefreshingCookies(true), 1000 * 60 * 20);
    return () => clearTimeout(timer);
  }, [isRefreshingCookies, iframeSrc, request]);

  // Catch user print event and pass it to the IFRAME
  useEffect(() => {
    function print() {
      const iframeOrigin = new URL(iframeSrc).origin;
      document.querySelector('iframe').contentWindow.postMessage(JSON.stringify({
        type: 'print',
      }), iframeOrigin);
    }
    function keydownListener(event) {
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 80) {
        print();
        event.preventDefault();
        event.stopImmediatePropagation();
      }
    }
    window.addEventListener('keydown', keydownListener);
    // Unfortunately, can't catch and cancel the 'beforeprint' event too
    return (() => {
      window.removeEventListener('keydown', keydownListener);
    });
  }, [iframeSrc]);

  return (
    <div className={styles.App}>
      <Navbar className={styles.Header}>
        <SelectPartner />
      </Navbar>
      <div className={styles.ProxySizing}>
        <Rive src="/thrasio.riv" className={styles.Loader} />
        <iframe
          className={styles.Proxy}
          title="Seller Central"
          allowtransparency="true"
          src={iframeSrc}
          allow="clipboard-write"
        />
        {isRefreshingSession && <RefreshingSession />}
      </div>
      {/*
      <div className={styles.Log}>
        Logging in...
      </div>
      */}
    </div>
  );
}

export default Home;
