import { Routes, Route } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { RequiredAuth } from 'components';
import Home from 'pages/Home';

function App() {
  return (
    <Routes>
      <Route path="/login/callback" element={<LoginCallback />} />
      <Route path="/" element={<RequiredAuth />}>
        <Route path="" element={<Home />} />
      </Route>
    </Routes>
  );
}

export default App;
