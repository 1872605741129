import React, { createContext, useContext, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import ApiContext from './Api';
import LoadingContext from './Loading';

const { useApi } = ApiContext;
const { useLoadingDispatch } = LoadingContext;

const RolesStateContext = createContext();

function RolesProvider({ children }) {
  const { authState } = useOktaAuth();
  const loadingDispatch = useLoadingDispatch();
  const { request } = useApi();

  const [oktaGroups, setOktaGroups] = useState([]);
  const [roles, setRoles] = useState([]);
  const [roleId, setRoleId] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('roleId');
  });

  useEffect(() => {
    loadingDispatch({
      type: 'set is roles data loading',
      isRolesDataLoading: true,
    });

    request('/gk/proxy-groups', undefined, true)
      .then((data) => {
        setOktaGroups(data);
      })
      .catch((err) => {
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authState?.isAuthenticated && authState?.isRoleAllowed && oktaGroups.length > 0) {
      // Get roles the current user can use
      // Users with the Okta group "Gatekeeper_Produsers" can use all roles
      const rolesAvailableToUser = authState.isRoleAllowed('Gatekeeper_Produsers')
        ? oktaGroups
        : oktaGroups.filter(({ group }) => authState.isRoleAllowed(group));

      if (authState.isRoleAllowed('SP_Root-All')) {
        rolesAvailableToUser.push({
          label: 'Root accounts',
          roleId: 'root',
        });
      }

      // Display in roles dropdown
      setRoles(rolesAvailableToUser);

      // Verify that the roleId selected is available to the current user
      if (!rolesAvailableToUser.find((role) => role.roleId === roleId)) {
        const params = new URLSearchParams(window.location.search);
        params.set('roleId', rolesAvailableToUser[0]?.roleId);
        window.history.replaceState(null, null, `?${params}`);
        setRoleId(rolesAvailableToUser[0]?.roleId);
      }

      loadingDispatch({
        type: 'set is roles data loading',
        isRolesDataLoading: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.isAuthenticated, authState?.isRoleAllowed, oktaGroups])

  return (
    <RolesStateContext.Provider value={{
      roles,
      roleId,
      setRoleId: (value) => {
        if (value) {
          const params = new URLSearchParams(window.location.search);
          params.set('roleId', value);
          window.history.replaceState(null, null, `?${params}`);
          setRoleId(value);
        }
      }
    }}>
      {children}
    </RolesStateContext.Provider>
  );
}

const useRolesState = () => {
  const context = useContext(RolesStateContext);
  if (context === undefined) {
    throw new Error('useRolesState must be used within a RolesProvider');
  }
  return context;
};

const RolesContext = {
  RolesProvider,
  useRolesState,
};
export default RolesContext;
