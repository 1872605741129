import React, { useEffect } from 'react';
import Select, { OptGroup, Option } from 'rc-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './index.module.scss';
import scConstants from 'scConstants';
import {
  ApiContext,
  IframeSrcContext,
  LoadingContext,
  PartnersContext,
  RolesContext,
  SiteContext,
} from 'providers';

const { useApi } = ApiContext;
const { useIframeSrcDispatch } = IframeSrcContext;
const {
  useLoadingDispatch,
  useLoadingState,
} = LoadingContext;
const { usePartnersState } = PartnersContext;
const { useRolesState } = RolesContext;
const { useSiteDispatch, useSiteState } = SiteContext;

const Hint = ({ children }) => (
  <div className={styles.Hint}>
    <FontAwesomeIcon className="fa-flip-horizontal" icon={['fas', 'arrow-turn-up']} />
    {children}
  </div>
);


function SelectPartner() {
  const { isLoggedIn, request } = useApi();
  const { roles, roleId, setRoleId } = useRolesState();

  // Get/set frame loading state
  const loadingDispatch = useLoadingDispatch();
  const { isIframeLoading, isPartnerDataLoading, statusText } = useLoadingState();

  // Store selected partner/marketplace
  const dispatch = useIframeSrcDispatch();

  // Store available data for the partner/marketplace dropdowns
  const {
    partners,
    partnerId,
    setPartnerId,
    marketplaces,
    marketplaceId,
    setMarketplaceId,
  } = usePartnersState();

  // Store information about selected site (e.g. Seller Central, Advertising, Vendor Central)
  const siteDispatch = useSiteDispatch();
  const {
    pathname,
    site: selectedSite,
    siteOptions,
  } = useSiteState();


  useEffect(() => {
    const partner = partners[partnerId];
    if (isLoggedIn && partner && marketplaceId) {
      document.title = `${partners[partnerId].name} - ${scConstants.marketplaceIds[marketplaceId]}`
    }
  },[isLoggedIn, partnerId, marketplaceId, partners])

  useEffect(() => {
    if (isLoggedIn && roleId && partnerId && marketplaceId) {
      (async () => {
        // Get sessionId (`{userIdFromRole}|{partnerId}|{marketplaceId}`) and
        // sessionHostname (MD5 hash of sessionId)
        const { error, sessionId, sessionHostname } = await request(
          `/gk/set-marketplace?${new URLSearchParams({
            roleId,
            partnerId,
            marketplaceId,
          })}`,
          {},
          // Ignore cache when making this request
          true,
        );
        if (error) {
          loadingDispatch({
            type: 'set is iframe loading',
            isIframeLoading: false,
          });
          console.error(error);
          return;
        }

        // Validate Okta JWT again, and set domain specific signed cookies
        const startSessionParams = new URLSearchParams({
          sessionId,
          origin: window.location.origin,
          site: selectedSite,
          roleId,
          ...(pathname ? { pathname } : undefined),
        });
        await request(`https://${selectedSite}-${sessionHostname}/gk/start-session?${startSessionParams}`);

        // Store new iframeSrc in our context provider
        dispatch({
          type: 'set values',
          iframeSrc: String(new URL(pathname || '', `https://${selectedSite}-${sessionHostname}`)),
        });
        loadingDispatch({
          type: 'set status text',
          statusText: null,
        });
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, roleId, partnerId, marketplaceId, selectedSite, pathname]);

  return (
    <>
      {/* Display a label showing the user's assigned role if there is only a single role available */}
      {roles.length === 1 && (
        <div className={`${styles.Wrapper} ${styles.RoleLabel}`}>
          <strong>Role:</strong>
          <div>{roles[0].label}</div>
        </div>
      )}
      {/* Otherwise show the user a dropdown to select the appropriate access role */}
      {roles.length > 1 && (
        <div className={styles.Wrapper}>
          <Select
            placeholder={<strong>Role</strong>}
            className={styles.Select}
            onChange={(value) => {
              const { location } = window;
              const params = new URLSearchParams(location.search);

              if (roleId && partnerId) {
                // We're switching role IDs, open in new window
                window.open(`${location.origin}${location.pathname}?roleId=${value}`);
              } else {
                // Add selected roleId to the params and set in our context provider
                window.history.replaceState(null, null, `?${params}`);
                setRoleId(value);
              }
            }}
            value={roleId}
            showSearch
            optionFilterProp="title"
          >
            {roles.map(({ roleId, label }) => (
              <Option key={roleId} value={roleId} title={label}>
                {label}
              </Option>
            ))}
          </Select>
          {roles.length > 1 && !roleId ? <Hint>Select a Role</Hint> : null}
        </div>
      )}
      <div className={styles.Wrapper}>
        <Select
          loading={isPartnerDataLoading}
          placeholder={<strong>Partner</strong>}
          className={styles.Select}
          onChange={(value) => {
            setPartnerId(value);
            if(selectedSite === 'advertising') {
              siteDispatch({
                type: 'set values',
                // should always be seller central when switching the value since we need to
                // go through the redirect that happens to ensure proper merchant id is loaded
                site: 'sellercentral',
                // Default the sellercentral home page to '/home'
                // Default the advertising home page to '/cm/ref=xx_cmpmgr_dnav_xx'
                pathname: value === 'sellercentral' ? '/home' : '/cm/ref=xx_cmpmgr_dnav_xx',
              });
            }
            loadingDispatch({
              type: 'set is iframe loading',
              isIframeLoading: true,
            });
          }}
          value={partnerId}
          showSearch
          optionFilterProp="title"
        >
          {Object.entries(partners || {})
            .sort(([,{ name: a }], [,{ name: b }]) =>
              a.toLowerCase() < b.toLowerCase() ? -1 : 1
            )
            .map(([SortKey, { name, names = [] }]) => (
              <Option
                value={SortKey}
                key={SortKey}
                title={names.join(', ')}
                className={styles.PartnerOption}
              >
                {name}
                {names.length > 1
                  ? (
                    <div className={styles.PartnerAlternateNames}>
                      <em>Also known as:</em>
                      <ul>
                        {names.filter((x) => name !== x).map((alt, i) => (
                          <li key={i}>{alt}</li>
                        ))}
                      </ul>
                    </div>
                  )
                  : null}
              </Option>
            ))}
        </Select>
        {!isPartnerDataLoading && roleId && !partnerId ? <Hint>Select a Brand</Hint> : null}
      </div>
      {partnerId && (
        <>
          <Select
            loading={isPartnerDataLoading}
            placeholder={<strong>Marketplace</strong>}
            className={styles.Select}
            onChange={(value) => {
              setMarketplaceId(value);
              // for the time-being we are always going to reset back to home
              // when marketplace is switched to ensure that there is not any
              // left over values in the proxy url that is only available in
              // the previous market.
              siteDispatch({
                type: 'set values',
                site: 'sellercentral',
                pathname: '/home'
              });
              loadingDispatch({
                type: 'set is iframe loading',
                isIframeLoading: true,
              });
            }}
            value={marketplaceId}
            showSearch
            optionFilterProp="title"
          >
            {Object.values(scConstants.regions).map(({ name: stack, marketplaceIds }) => (
              <OptGroup key={stack} label={stack}>
                {marketplaceIds
                  .filter((id) => marketplaces.includes(id))
                  .map((id) => {
                    const marketplaceName = scConstants.countryCodes[scConstants.marketplaceIds[id]];
                    return (
                      <Option value={id} key={id} title={marketplaceName}>
                        {marketplaceName}
                      </Option>
                    );
                  })}
              </OptGroup>
            ))}
          </Select>
          <Select
            loading={isPartnerDataLoading}
            placeholder={<strong>Site</strong>}
            className={styles.Select}
            onChange={(value) => {
              siteDispatch({
                type: 'set values',
                // should always be seller central when switching the value since we need to
                // go through the redirect that happens to ensure proper merchant id is loaded
                site: 'sellercentral',
                // Default the sellercentral home page to '/home'
                // Default the advertising home page to '/cm/ref=xx_cmpmgr_dnav_xx'
                pathname: value === 'sellercentral' ? '/home' : '/cm/ref=xx_cmpmgr_dnav_xx',
              });
              loadingDispatch({
                type: 'set is iframe loading',
                isIframeLoading: true,
              });
            }}
            value={selectedSite}
            showSearch
            optionFilterProp="title"
          >
            {siteOptions.map(({ id, name }) => (
              <Option key={id} value={id} title={name}>
                {name}
              </Option>
            ))}
          </Select>
        </>
      )}
      {isIframeLoading && (
        <FontAwesomeIcon className={styles.Spinner} icon={['fas', 'circle-notch']} spin />
      )}
      {isIframeLoading && statusText && (
        <span className={styles.Loading}>{statusText}</span>
      )}
    </>
  );
}

export default SelectPartner;
